import React from 'react'
import Layout from '../layout/Layout'
import ContactDetails from './components/contact/ContactDetails'
import Map from './components/contact/Map'
import SalonGallery from './components/contact/SalonGallery'
import Footer from './components/shared/Footer'
import NavBar from './components/shared/NavBar'

const ContactPage = () => {
  return (
    <Layout>
      <NavBar />
      <div className="page-header-background-image page-header-contact-background-image">
        <div className="container py-5 text-center">
          <h1 className="p-5">Contact Us</h1>
        </div>
      </div>
      <div className="mustard-divider"></div>
      <ContactDetails />
      <Map />
      <SalonGallery />
      <Footer />
    </Layout>
  )
}

export default ContactPage
