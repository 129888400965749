import React from 'react'

const Salon = () => {
  return (
    <div className="background-colour-beige-3">
      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gy-4 py-5">
          <div className="col">
            <div className="card gallery-background-image salon-1"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-2"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-3"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-4"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-5"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-6"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-7"></div>
          </div>
          <div className="col">
            <div className="card gallery-background-image salon-8"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Salon
