import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const ContactDetails = () => {
  return (
    <div className="background-colour-beige-1">
      <div className="container py-5 text-center">
        <StaticImage
          className="lhl-logo"
          alt="little hair lounge logo"
          placeholder="blurred"
          height={200}
          src={'../../../images/lhl-logo-cropped.png'}
        ></StaticImage>
        <p>
          Our salon is open by appointment <br></br>
          <b>Tuesday to Saturday.</b>
        </p>
        <p>
          Call or Text <br></br> <b> 0493242852</b>
        </p>
        <p>
          Email <br></br>
          <b>info@littlehairlounge.com.au</b>
        </p>
        <p>
          Salon Location <br></br>
          <b>24 Ferguson Avenue, Labrador QLD 4215</b>
        </p>
      </div>
    </div>
  )
}

export default ContactDetails
