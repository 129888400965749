import React from 'react'

const Map = () => {
  return (
    <div className="map-responsive">
      <iframe
        title="google maps little hair lounge"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d509.04330411106537!2d153.40106121893848!3d-27.93985778005414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b910f8c0cf4bc93%3A0x8d334d2658bc7a0a!2sLittle%20Hair%20Lounge!5e0!3m2!1sen!2sau!4v1646919297019!5m2!1sen!2sau"
        style={{ border: 0 }}
        allowFullScreen={false}
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
